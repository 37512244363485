import React from "react";

import Jumbotron from "react-bootstrap/Jumbotron";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";

import logo from "./images/Logo.png";

const styles = {
  jumbotron: () => ({
    backgroundColor: "transparent",
    fontFamily: "Calibri, sans-serif",
    marginBottom: "-50px",
  }),
  jumbotronMessage: () => ({
    lineHeight: "1.4",
    fontSize: "21px",
    fontWeight: "200",
  }),
  logoBoxing: () => ({
    position: "relative",
    maxHeight: "100%",
    maxWidth: "100%",
    width: "auto",
  }),
  logo: () => ({
    maxHeight: "100%",
    maxWidth: "100%",
    width: "auto",
    marginBottom: "20px",
  }),
};

export default ({ executeScroll }) => (
  <section id="brand">
    <Container>
      <Jumbotron style={styles.jumbotron()}>
        <div style={styles.logoBoxing()}>
          <img style={styles.logo()} src={logo} alt="Logo" />
          <Button
            id="enquiryButton"
            varient="primary"
            size="lg"
            onClick={executeScroll}
          >
            Enquiries &raquo;
          </Button>
        </div>
        <p style={styles.jumbotronMessage()}>
          Software services and consulting to find innovative solutions to
          complex business and technical challenges.
          <br />
          Customer focused, quality driven, Windows&reg; and embedded software
          development.
        </p>
      </Jumbotron>
    </Container>
  </section>
);
