import React from "react";

import location from "./images/Location-Marker.png";

export default () => (
  <h2>
    <div style={{ textAlign: "left", marginBottom: "20px" }}>
      <img
        style={{ verticalAlign: "middle", marginRight: "5px", opacity: "0.9" }}
        src={location}
        alt="office location"
      />
      <span style={{ verticalAlign: "middle", padding: "2px" }}>Rookery Technology Offices</span>
    </div>
  </h2>
);
