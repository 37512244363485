import React, { useState } from 'react';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const styles = ({
    footer: () => ({
        paddingTop: "10px",
        paddingBottom: "20px",
        paddingLeft: "15px",
        paddingRight: "15px",
        color: "darkgray",
        textAlign: "center",
        fontSize: "x-small",
    }), 
    privacyLink: () => ({
      background: "transparent",
      border: "none",
      padding: "0!important",
      textDecoration: "underline",
      cursor: "pointer",
    }),
  });

export default () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Privacy Policy</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p>Rookery Technology collects data on clients and people who enquire about its services. 
            The information collected consists of names, job titles and contact email addresses. These data are stored in secure cloud based services and are 
            only accessible by the company director. The information will be kept as long as the company has a reasonable expectation that it may have 
            future interaction with that client.</p>
            <p>To request access to the data we hold about you or to ask us to remove it, please contact chris.hockey@rookerytechnology.co.uk.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <footer style={styles.footer()}>
        Rookery Technology Ltd is a limited company registered in England and
        Wales.
        <br />
        Registered Offices: Unit 11, Hove Business Centre, Fonthill Road, Hove,
        East Sussex, BN3 6HA
        <br />
        Company Registration No: 09016781
        <br />
        VAT Registration No: 188506081
        <br />
        <button style={styles.privacyLink()} onClick={handleShow}>
          Privacy policy
        </button>
      </footer>
    </>
  );
};
