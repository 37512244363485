import React from "react";

import arrowUp from "./images/Arrowhead-Up.png";

export default ({ title, executeScroll }) => (
  <h2>
    <div style={{ textAlign: "left" }}>
      <img
        style={{ verticalAlign: "middle", marginRight: "5px", opacity: "0.9" }}
        src={arrowUp}
        alt="return arrow"
        onClick={executeScroll}
      />
      <span style={{ verticalAlign: "middle", padding: "2px" }}>{title}</span>
    </div>
  </h2>
);
