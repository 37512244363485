import React, { useState, useRef } from 'react';

import { Container, Row, Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';

import Navigation from './Navigation'
import Branding from './Branding'
import ContactInformation from './Contact'
import Legal from './Legal'
import ReturnHeader from './ReturnHeader'

import './App.css';

import profile from './images/profile.jpg';
import contact from './images/Contact.png';
import mail from './images/Mail.png';
import linkedin from './images/LinkedIn.png';

const styles = ({
  profile: () => ({
      width: "33.333333%",
      minWidth: "320px",
      float: "left",
      marginRight: "30px",
      fontSize: "medium",
  }),
  profileImage: () => ({
      maxHeight: "100%",
      maxWidth: "100%",
      width: "auto",
      opacity: "0.9",
  }),
  quote: () => ({
    fontSize: "small",
    fontStyle: "italic",
  }),
  aboutBody: () => ({
    marginTop: "10px",
  }),
});

const scrollToTop = () => window.scrollTo(0,0);

const AboutMe = () => (
  <>
    <p>With over ten years experience of complex software development for Windows® and embedded platforms.</p>
    <p>I have worked as part of a highly focused, delivery driven, Scrum team. Practising Pair Programming and Test Driven Development, using tools like SpecFlow, Moq and MsTest.</p>
    <p>With strong hardware and software debugging skills, I provide an effective communication medium across the different disciplines within a product development team.</p>
    <p>I have experience of continuous integration and build automation using Azure DevOps and Git</p>

    <p>My commercial experience includes:</p>

        <ul>
            <li>Behaviour Driven Development</li>
            <li>Complex Windows desktop application design in C# </li>
            <li>Windows Presentation Framework (WPF) with Entity Framework</li>
            <li>Working within an Agile, Scrum environment</li>
            <li>Windows Forms</li>
            <li>C++ Linux applications</li>
            <li>Multi-threading</li>
            <li>Design patterns</li>
            <li style={{fontStyle:"italic"}}>And much more...</li>
        </ul>
    </>
);

const AboutEngineering = () => (
  <>
    <p>With over ten years experience of complex software and firmware development in a manufacturing, engineering environment.</p>
    <p>I have developed firmware for microcontrollers including Atmel AVR, Cyrpess FX2LP, and ARM Cortex-M.</p>
    <p>I have experience of Structured Analysis and Design using Yourdon, and I have used TDD techniques in embedded system development.</p>
    <p>With strong hardware and software debugging skills, I provide an effective communication medium across the different disciplines within a product development team.</p>

    <p>My commercial experience includes:</p>

        <ul>
            <li>Microcontroller firmware design and implementation</li>
            <li>Bluetooth, BLE, USB and other protocols</li>
            <li>Device interfacing software in C#</li>
            <li>C++ Linux applications</li>
            <li>Hardware debugging and fault detection for prototype PCBs</li>
            <li>Windows Presentation Framework (WPF)</li>
            <li>Multi-threading</li>
            <li>Design patterns</li>
            <li style={{fontStyle:"italic"}}>And much more...</li>
        </ul>
  </>
);

const AboutTechnology = () => (
  <>
    <p>With over ten years experience of complex software development for Windows®, Linux and bare metal devices</p>
    <p>I have worked as part of multiple highly focused, delivery driven, Scrum teams. Using Behaviour Driven and Test Driven Development techniques.</p>
    <p>I have experience of continuous integration and build automation.</p>
    <p>I have a passion for new technology and drive to constantly improve myself through self learning and external training.</p>

    <p>My commercial experience includes:</p>

        <ul>
            <li>Behaviour Driven Development (Specflow, MsTest, Gherkin, Moq, FluentAssertions, NCrunch)</li>
            <li>Complex Windows desktop application design in C# for Microsoft .NET</li>
            <li>Windows Presentation Framework (WPF), Unity, Entity Framework</li>
            <li>Windows Communication Foundation (WCF)</li>
            <li>NodeJS, React</li>
            <li>Design patterns</li>
            <li>IoT</li>
            <li style={{fontStyle:"italic"}}>And much more...</li>
        </ul>

    <p>My latest technology interests include NodeJS, React and Azure Functions.</p>
  </>
);

const quotes = {
  about: "I am passionate about producing high quality solutions",
  engineering: "I have an interest in software development from high level application architecture to low level firmware implementation.",
  technology: "I am a champion of new technologies",
};

const aboutInfos = {
  about: <AboutMe />,
  engineering: <AboutEngineering />,
  technology: <AboutTechnology />,
};

const FocusAreas = ({ setCurrentQuote, setAbout, executeScroll }) => {

  const updateAbout = (quote, aboutInfo) => {
    setCurrentQuote(quote);
    setAbout(aboutInfo);
    executeScroll();
  };

  return (
    <section id="areas">
      <Container>
          <Row>
              <Col id="brand-customer" md={4}>
                  <h2>Customer focused</h2>
                  <p>
                      Comfortable working in a Agile Scrum environment. Highly motivated, reliable and focused on delivering real customer value.
                      Quality delivery using the latest in behaviour and test driven development techniques.
                  </p>
                  <Button variant="light" onClick={() => updateAbout(quotes.about, aboutInfos.about)}>Learn more &raquo;</Button>
              </Col>
              <Col id="brand-engineering" md={4}>
                  <h2>Engineering specialist</h2>
                  <p>
                      Highly experienced in scientific and engineering industry applications, understanding the complexity this involves.
                      From multi-threaded, device interfacing software to bare metal microcontrolller firmware development.
                  </p>
                  <Button variant="light" onClick={() => updateAbout(quotes.engineering, aboutInfos.engineering)}>Learn more &raquo;</Button>
              </Col>
              <Col id="brand-technology" md={4}>
                  <h2>Technology enthusiast</h2>
                  <p>
                      Evolving with the latest software engineering practices. Development using the latest Microsoft technologies.
                      Experience with continuous integration and build automation.
                  </p>
                  <Button variant="light" onClick={() => updateAbout(quotes.technology, aboutInfos.technology)}>Learn more &raquo;</Button>
              </Col>
          </Row>
      </Container>
    </section>
  );
};

const TechnologySummary = () => (
  <Table striped bordered hover size="sm">
    <thead>
      <tr>
        <th colSpan="3">TECHNICAL SUMMARY</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>C/C++</td>
        <td>RTOS, Linux</td>
        <td>TDD, BDD</td>
      </tr>
      <tr>
        <td>C# .NET</td>
        <td>USB, Bluetooth, BLE, SPI, I2C, UART</td>
        <td>SpecFlow, Moq, MsTest, Catch2</td>
      </tr>
      <tr>
        <td>WPF</td>
        <td>NodeJS, React</td>
        <td>Entity framework</td>
      </tr>
      <tr>
        <td>Microcontrollers
          <ul style={{paddingLeft:"15px", fontSize:"9pt"}}>
            <li>ARM Cortex-M</li>
            <li>Nordic nRF</li>
            <li>Atmel AVR</li>
            <li>8051 variants</li>
          </ul>
        </td>
        <td>Yourdon Structured Analysis<br/>UML</td>
        <td>Visual studio<br/>Git</td>
      </tr>
    </tbody>
  </Table>
);

const AboutSection = ({ quote, children, aboutRef }) => (
  <section id="about" ref={aboutRef}>
    <Container>
        <Row>
          <Col md={12}>
            <ReturnHeader title="About" executeScroll={scrollToTop} />
            <TechnologySummary />
            <aside style={styles.profile()}>
              <img src={profile} alt="Chris Hockey profile" style={styles.profileImage()} />
              <h4>Chris Hockey, Director and Software consultant</h4>
              <p style={styles.quote()}>"{quote}"</p>
              <div className="section-header">
                  <img src={contact} alt="Contact" />
                  <span>07833 298547</span>
              </div>
              <div className="section-header">
                  <a href="mailto:chris.hockey@rookerytechnology.co.uk">
                      <img src={mail} alt="Email Chris Hockey chris.hockey@rookerytechnology.co.uk" />
                  </a>
                  <a href="http://uk.linkedin.com/in/rookerytech">
                      <img src={linkedin} alt="View Chris Hockey's profile on LinkedIn" />
                  </a>
              </div>
            </aside>
            <section style={styles.aboutBody()}>
              {children}
            </section>
          </Col>
        </Row>
    </Container>
  </section>
);

const scrollToRef = (ref, navRef) => {
  window.scrollTo(0, ref.current.offsetTop - navRef.current.clientHeight);
};

const App = () => {
  const [currentQuote, setCurrentQuote] = useState(quotes.about);
  const [currentAbout, setAbout] = useState(<AboutMe />);

  const aboutRef = useRef(null);
  const contactRef = useRef(null);
  const navRef = useRef(null);

  const executeScrollAbout = () => scrollToRef(aboutRef, navRef);
  const executeScrollContact = () => scrollToRef(contactRef, navRef);

  return (<>
      <Navigation executeScrollHome={scrollToTop} executeScrollAbout={executeScrollAbout} executeScrollContact={executeScrollContact} navRef={navRef} />
      <Branding executeScroll={executeScrollContact} />
      <FocusAreas setCurrentQuote={setCurrentQuote} setAbout={setAbout} executeScroll={executeScrollAbout} />
      <AboutSection quote={currentQuote} aboutRef={aboutRef}>{currentAbout}</AboutSection>
      <ContactInformation contactRef={contactRef} executeScrollHome={scrollToTop} />    
      <Legal />
    </>
  );
}

export default App;
