import React, { useState } from "react";

import { Container, Row, Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";

import ReturnHeader from "./ReturnHeader";
import LocationHeader from "./LocationHeader";

import ReCAPTCHA from "react-google-recaptcha";

const styles = {
  officeAddress: () => ({
    marginLeft: "15px",
  }),
  contactForm: () => ({
    width: "80%",
  }),
};

const ContactForm = ({ setFormSubmitted }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [token, setToken] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();

    setFormSubmitted(
      <div style={{ textAlign: "left" }}>
        <Spinner
          animation="border"
          role="status"
          style={{ verticalAlign: "middle", marginRight: "5px" }}
        >
          <span className="sr-only">Sending...</span>
        </Spinner>
        <span style={{ verticalAlign: "middle", padding: "2px" }}>
          Sending...
        </span>
      </div>
    );

    fetch(
      `https://rookery-contact-sender.azurewebsites.net/api/SendContactRequest?code=${process.env.REACT_APP_AZURE_FUNC_KEY}`,
      {
        method: "POST",
        body: JSON.stringify({ name, email, message, token }),
      }
    )
      .then((res) => {
        if (res.status === 200) {
          /*OK*/ setFormSubmitted(
            <p>Thank you for your query. I will be in touch soon.</p>
          );
        } else {
          setFormSubmitted(
            <p>
              Something went wrong. Please try the direct contact information
              above.
            </p>
          );
        }
      })
      .catch((error) => {
        setFormSubmitted(
          <p>
            Something went wrong. Please try the direct contact information
            above.
          </p>
        );
      });
  };

  return (
    <section style={styles.contactForm()}>
      <Form style={{ marginLeft: "10px" }} onSubmit={handleSubmit}>
        <Form.Group>
          <Form.Label>Name:</Form.Label>
          <Form.Control
            required
            size="sm"
            type="text"
            placeholder="Enter your name"
            value={name}
            onChange={(ev) => setName(ev.target.value)}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Email Address:</Form.Label>
          <Form.Control
            required
            size="sm"
            type="email"
            placeholder="Enter a contact email"
            value={email}
            onChange={(ev) => setEmail(ev.target.value)}
          />
          <Form.Text className="text-muted">
            We'll never share your email with anyone else.
          </Form.Text>
        </Form.Group>
        <Form.Group>
          <Form.Label>Message:</Form.Label>
          <Form.Control
            required
            size="sm"
            as="textarea"
            rows="5"
            placeholder="What is your query?"
            value={message}
            onChange={(ev) => setMessage(ev.target.value)}
          />
        </Form.Group>
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
          onChange={(value) => setToken(value)}
        />
        <Button variant="light" type="submit" disabled={!token}>
          Send
        </Button>
      </Form>
    </section>
  );
};

const OfficeAddress = () => (
  <section style={styles.officeAddress()}>
    <LocationHeader />
    <h4>Oxfordshire</h4>
    <address>
      15 Pipit Lane
      <br />
      Witney, OX28 6NU
    </address>
    <p>Telephone: 07833 298547</p>
  </section>
);

export default ({ contactRef, executeScrollHome }) => {
  const [formSubmitted, setFormSubmitted] = useState(null);

  return (
    <section id="contact" ref={contactRef}>
      <Container>
        <Row>
          <Col>
            <ReturnHeader title="Contact" executeScroll={executeScrollHome} />
            {!formSubmitted ? (
              <ContactForm setFormSubmitted={setFormSubmitted} />
            ) : (
              <>{formSubmitted}</>
            )}
          </Col>
          <Col>
            <OfficeAddress />
          </Col>
        </Row>
      </Container>
    </section>
  );
};
