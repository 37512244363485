import React from "react";

import Container from "react-bootstrap/Container";
import { Nav, Navbar } from "react-bootstrap";

export default ({
  executeScrollHome,
  executeScrollAbout,
  executeScrollContact,
  navRef,
}) => (
  <Navbar fixed="top" className="navbar-inverse" variant="dark" expand="lg" ref={navRef}>
    <Container>
      <Navbar.Brand onClick={executeScrollHome}>
        Rookery Technology Ltd
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link onClick={executeScrollHome}>Home</Nav.Link>
          <Nav.Link onClick={executeScrollAbout}>About</Nav.Link>
          <Nav.Link onClick={executeScrollContact}>Contact</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Container>
  </Navbar>
);
